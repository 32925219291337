import React from "react";
import {mouse} from "./reactI";
import {CMiniMenuForGraph} from "./Elements/miniMenu";


export function RightClickMenu ({children, cor}:{children: React.ReactElement, cor?:{x:number, y:number}}) {
    const data = {x:0,y:0}

    const [show, setShow] = React.useState<{status: boolean, coordinate?: {x: number, y: number}}>({status: false})
    let coordinate= {x: 0, y: 0}
    const coordinateF = () => coordinate
    setTimeout(()=> {
        if (!show.status) {
            setShow({status: true})
        }
    }, 500)



    return <div style={{height: "100%", position: "relative", minWidth: "500px"}}
                onContextMenu={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                ref={(e) => {
                    if (e) {
                        const r = e.getBoundingClientRect()
                        data.x = r.x
                        data.y = r.y
                    }
                }}
    >
        {children}

            <CMiniMenuForGraph
                onDeInit={() => {
                    setShow({status: true})
                }
                }
                coordinate={{...show.coordinate!}}
            />
    </div>
}